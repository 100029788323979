import * as React from 'react';
import cn from 'classnames';
import { Collapse as RCollapse } from 'react-collapse';
import { IconArrowDown, IconArrowUp } from 'styleguide/icons';

import Grid from '../Grid/Grid';

interface TitleProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onClick: () => void;
  expanded: boolean;
  className?: string;
  isWide?: boolean;
  fixedIcon?: boolean;
  centered?: boolean;
  iconClassName?: string;
  iconColor?: string;
}

const ItemTitle = ({ fixedIcon = true, centered, iconClassName, iconColor, ...props }: TitleProps) => (
  <Grid.Container
    onClick={props.onClick}
    className={cn(
      'flex w-auto cursor-pointer items-center justify-between',
      props.className,
      props.isWide ? 'p-0' : 'md:px-5',
    )}
  >
    <Grid className="w-full" noPadding={!!props.isWide}>
      <Grid.Row>
        <Grid.Col sm={0} md={props.isWide ? 0 : 1} />
        <Grid.Col
          sm={12}
          md={props.isWide ? 12 : 10}
          className={cn('relative !flex w-full !items-center', centered ? '!justify-center' : '')}
        >
          <div>{props.children}</div>
          <div className={cn({ 'absolute right-3': !!fixedIcon })}>
            {props.expanded ? (
              <IconArrowUp color={iconColor} className={iconClassName} />
            ) : (
              <IconArrowDown color={iconColor} className={iconClassName} />
            )}
          </div>
        </Grid.Col>
        <Grid.Col sm={0} md={props.isWide ? 0 : 1} />
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

const ItemBody = ({ expanded, withPadding, isWide, children, ...props }) => (
  <RCollapse
    isOpened={!!expanded}
    theme={{
      collapse: cn('transition-height duration-500', props.collapseClassName),
      content: cn('overflow-hidden', props.className, withPadding ? 'p-4' : 'md:px-5'),
    }}
  >
    <Grid.Container>
      <Grid className={cn(isWide ? '!px-0' : '')}>
        <Grid.Row>
          <Grid.Col sm={0} md={isWide ? 0 : 1} />
          <Grid.Col sm={12} md={isWide ? 12 : 10}>
            {children}
          </Grid.Col>
          <Grid.Col sm={0} md={isWide ? 0 : 1} />
        </Grid.Row>
      </Grid>
    </Grid.Container>
  </RCollapse>
);

interface Props {
  title: React.ReactNode;
  body: React.ReactNode;
  expanded?: boolean;
  onToggle?: () => void;
  classNameTitle?: string;
  classNameBody?: string;
  isWide?: boolean;
  fixedIcon?: boolean;
  centeredTitle?: boolean;
  iconClassName?: string;
  withPadding?: boolean;
  iconColor?: string;
  showMore?: boolean;
  initiallyExpanded?: boolean;
}

interface State {
  expanded: boolean;
}

class Item extends React.Component<Props, State> {
  static getDerivedStateFromProps(props, state) {
    if (typeof props.expanded === 'undefined') {
      return state;
    }

    return {
      expanded: !!props.expanded,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/destructuring-assignment
      expanded: !!this.props.expanded || !!this.props.initiallyExpanded,
    };
  }

  toggleExpanded = () => {
    const { expanded } = this.state;
    const { onToggle } = this.props;
    this.setState({ expanded: !expanded });
    if (onToggle) {
      onToggle();
    }
  };

  render() {
    const {
      classNameBody,
      classNameTitle,
      isWide,
      showMore,
      withPadding,
      fixedIcon,
      centeredTitle,
      iconClassName,
      iconColor,
      title,
      body,
    } = this.props;

    const { expanded } = this.state;
    const collapseTitle = (
      <ItemTitle
        onClick={this.toggleExpanded}
        expanded={expanded}
        className={classNameTitle}
        isWide={isWide}
        fixedIcon={fixedIcon}
        centered={centeredTitle}
        iconClassName={iconClassName}
        iconColor={iconColor}
      >
        {title}
      </ItemTitle>
    );
    const collapseBody = (
      <ItemBody expanded={expanded} className={classNameBody} isWide={isWide} withPadding={withPadding}>
        {body}
      </ItemBody>
    );
    return showMore ? (
      <>
        {collapseBody} {collapseTitle}
      </>
    ) : (
      <>
        {collapseTitle}
        {collapseBody}
      </>
    );
  }
}

const Collapse = ({ children, ...otherProps }: React.HTMLProps<HTMLDivElement>) => (
  <div {...otherProps}>{children}</div>
);

Collapse.Item = Item;

export default Collapse;
